<template>
  <div>
    <b-table stacked="md" fixed responsive res :items="mapItems" :fields="fields">
      <template #cell(orders)="{ item }">
        <ul class="rma-orders">
          <li v-for="order in item.orders" :key="order.order_number">
            <router-link class="rma-orders_link bcm-link bcm-link-primary" :to="{ name: 'myOrder', params: { id: order.order_id }}" v-html="`#${order.order_number}`"/>
            <!-- <div>
              <article class="rma-product" v-for="(product, index) in order.items" :key="index">
                <img-product
                  :imgPath="product.image_url"
                  :alt="product.item_name"
                  :styleImage="{ maxWidth: '60px' }"
                />
              </article>
            </div> -->
          </li>
        </ul>
      </template>
      <template #cell(status_name)="{ item }">
        <!-- <b-badge pill :class="[item.status_color]">{{ item.status_name }}</b-badge> -->
        <b>{{ item.status_name }}</b>
      </template>
      <template #cell(actions)="{ item }">
        <div class="text-right">
          <router-link :to="{ name: 'editReturns', params: { id: item.guest_id } }" v-t="'rma.viewReturn'" class="bcm-link bcm-link-primary"/>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import moment from 'moment'
import ImgProduct from '@/components/Product/Image'

export default {
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    mapItems () {
      const _this = this
      return this.items.map(item => ({
        id: `#${item.id}`,
        created_at: moment(item.created_at).format(_this.$t('dateFormat')),
        status_name: item.status_name,
        guest_id: item.guest_id,
        status_color: `mst-rma-status status-branch-${item.status_color}`,
        orders: item.orders
      }))
    },
    fields () {
      return [{
        key: 'id',
        label: this.$t('rma.code')
      },
      {
        key: 'orders',
        label: this.$t('order.order')
      },
      {
        key: 'created_at',
        label: this.$t('order.data')
      },
      {
        key: 'status_name',
        label: this.$t('order.status')
      },
      'actions']
    }
  },
  components: {
    ImgProduct
  }
}
</script>
<style lang="scss" scoped>
.rma-orders{
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  li,
  article div,
  article{
    display: inline-block;
  }
}
</style>
